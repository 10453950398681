<script>
  export default {
    props: ["views", "showViews", "openViews"],
    computed: {
      totalViews() {
        return this.views?.length;
      },
    },
    methods: {
      setViews() {
        if (!this.openViews) {
          return;
        }

        this.openViews(this.views);
      },
    },
  };
</script>

<template>
  <section class="views">
    <template>
      <iconic class="views__eye" name="eye_contets" @click.native.stop="setViews" />
    </template>
    <p :class="['views__total', openViews && 'views__total--method']" @click.stop="setViews" v-if="showViews">{{ views.length }}</p>
  </section>
</template>

<style lang="scss" scoped>
  .views {
    @include Flex(row);
    gap: 10px;
    color: #000;
    &__eye {
      transform: scale(1.4);
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.5);
      }
    }
    &__total {
      padding: 0 5px;
      font-family: $newFont-regular;
      &--method {
        cursor: pointer;
      }
    }
  }
</style>
